/* eslint-disable camelcase */
import React, { useState, useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { graphql } from 'gatsby'
import urljoin from 'url-join'
import Layout from '@layouts/MainLayout'
import Container from '@layouts/Container'
import { Box } from '@lib/ui/core'
import SEO from '@components/SEO'
import AppLink from '@components/AppLink'
import JsonLd from '@components/JsonLd'
import TagShowMore from '@components/Tag/ShowMore'
import { parseTag, parseOffer } from '@services/prismic/helpers'
import itemListSchema from '@utils/json-ld/item-list'
import { linkResolver } from '@utils/link-resolver'
import OfferList from '@components/Offer/List/Card'
import Post from '@components/Post/PostList/Card'
import SearchInput from "@components/Search/SearchInput"
import { useAllCreditCard } from '@hooks/use-all-credit-card'
import Typography from '@lib/ui/core/Typography'

const TagPage = ({ data, location, pageContext }) => {
  const doc = data && data.tag
  if (!doc) return null
  const tag = parseTag(doc)

  const { meta_keywords, meta_description, og_image } = tag
  const offersDoc = data && data.offers && data.offers.nodes
  const offerTotalCount = data && data.offers && data.offers.totalCount
  const offers = parseOffer(offersDoc)
  const offerLast = offers && offers[0] && offers.last_publication_date

  const postsDoc = data && data.posts && data.posts.nodes
  const postTotalCount = data && data.posts && data.posts.totalCount
  const posts = parseOffer(postsDoc)
  const postLast = posts && posts[0] && posts.last_publication_date

  const lastPublicationDate = postLast < offerLast ? postLast : offerLast

  const { siteMetadata } = data.site

  const toggleSearchDrawer = useStoreActions(
    ({ offerSearch }) => offerSearch.toggleSearchDrawer
  )
  const updateCreditCards = useStoreActions(({ allCreditCards }) => allCreditCards.update);
  const allCreditCardData = useAllCreditCard()

  useEffect(() => {
    toggleSearchDrawer(false)
    updateCreditCards(allCreditCardData)
  }, [])


  const items = []
  // let count = 0
  // console.log("postlen", posts.length)
  // for (let i = 0; count < (offers.length + posts.length); i += 1) {
  //   const offerEnd = (offeritem * (i + 1) > offers.length) ? offers.length : offeritem * (i + 1)
  //   // const postEnd = postitem * (i + 1) > posts.length ? posts.length : postitem * (i + 1)

  //   if (offeritem * i < offers.length) {
  //     items.push(<OfferList fullWidth offers={offers.slice(offeritem * i, offerEnd)} />)
  //   }
  //   if (postitem * i < posts.length) {
  //     console.log(posts[i])
  //     items.push(<Box mb={2}><Post fullWidth post={posts[i]} /></Box>)
  //   }
  //   count = offerEnd + i
  // }
  let offerCount = 0
  let PostCount = 0
  for (let i = 0; i < (offers.length + posts.length); i += 1) {
    for (let j = 0; j < 3 && offerCount < offers.length; j += 1) {
      items.push(<Box key={offers[offerCount].prismicId} mb={2.5}><OfferList fullWidth offer={offers[offerCount]} /></Box>)
      offerCount += 1
    }
    if (PostCount < posts.length) {
      items.push(<Box key={posts[i].prismicId} mb={2.5}><Post fullWidth post={posts[i]} /></Box>)
      PostCount += 1
    }
  }

  return (
    <Layout headerTitle={`${tag.name} (${offerTotalCount + postTotalCount})`}>
      <SEO
        title={`${tag.name}`}
        description={meta_description}
        keywords={meta_keywords}
        image={og_image && og_image.url}
      />
      <AppLink path={location.pathname} />
      <JsonLd>
        {itemListSchema({
          itemListElement: offers.map((offer) => ({
            url: urljoin(siteMetadata.siteUrl, linkResolver(offer)),
          })),
        })}
      </JsonLd>
      <Container>
        <Box display="none" position="relative" mt="-1.5rem" mb={{ xs: 2.5, sm: 4 }}>
          <SearchInput height="10rem" />
        </Box>
      </Container>
      <Container>
        <Box maxWidth={648} m="auto" my={{ xs: 3.5, sm: 7.5 }}>
          <Box mb={2} mt={4}>
            <Typography variant="h2">#{tag.name}</Typography>
          </Box>
          {Array.isArray(items) && items}
          <TagShowMore
            tagId={pageContext.id}
            lastPublicationDate={lastPublicationDate}
            totalCount={offerTotalCount + postTotalCount}
            pageSize={15}
            filter={{ tagId: tag.prismicId }}
          />
        </Box>
      </Container>
    </Layout>
  )
}

export default TagPage

export const query = graphql`
  query TagListQuery($id: ID!, $today: Date) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    tag: prismicTag(prismicId: { eq: $id }) {
      data {
        name
        meta_keywords
        meta_description
        og_image {
          url
        }
      }
      prismicId
      uid
      type
    }
    offers: allPrismicOffer(
      limit: 12,
      sort: {
        fields: [
          data___date,
          last_publication_date
        ]
        order: [
          DESC,
          DESC
        ]
      },
      filter: {
        data: {
          to: {
            gte: $today
          },
          tagged: {
            elemMatch: {
              tag: {
                id: {
                  eq: $id
                }
              }
            }
          }
        }
      }
    ) {
      totalCount
      nodes {
        data {
          offer_category {
            document {
              ... on PrismicOfferCategory {
                data {
                  name
                }
                uid
                type
              }
            }
          }
          offer_subcategory {
            document {
              ... on PrismicOfferSubcategory {
                uid
                type
                data {
                  name
                  offer_category {
                    uid
                    type
                  }
                  image {
                    url
                  }
                  image_color
                }
              }
            }
          }
          featured_images {
            image {
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 240) {
                    ...GatsbyPrismicImageFluid_noBase64
                  }
                }
              }
              alt
            }
          }
          merchants {
            merchant {
              document {
                ... on PrismicMerchant {
                  uid
                  data {
                    name
                  }
                }
              }
              url
            }
          }
          banks {
            bank {
              document {
                ... on PrismicBank {
                  uid
                  prismicId
                  data {
                    name
                  }
                }
              }
            }
          }
          credit_cards {
            credit_card {
              document {
                ... on PrismicCreditCard {
                  uid
                  prismicId
                  data {
                    name
                    bank {
                      id
                    }
                    card_image {
                      alt
                      url
                      dimensions {
                        width
                        height
                      }
                      fixed(height: 42){
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          title
          to(formatString: "LL", locale: "zh-tw")
        }
        prismicId
        last_publication_date
        uid
        type
      }
    }
    posts: allPrismicPost(
      sort: {
        fields: [
          data___date,
          last_publication_date
        ]
        order: [
          DESC,
          DESC
        ]
      },
      filter: {
        data: {
          tagged: {
            elemMatch: {
              tag: {
                id: {
                  eq: $id
                }
              }
            }
          }
        }
      }
    ) {
      totalCount
      nodes {
        prismicId
        last_publication_date
        uid
        type
        data {
          title
          post_category {
            uid
            type     
            document {
              ... on PrismicPostCategory {
                id
                data {
                  name
                }
              }
            }
          }
          post_subcategory {
            uid
            type
            document {
              ... on PrismicPostSubcategory {
                id
                data {
                  name
                  post_category {
                    uid
                  }
                }
              }
            }
          }
          lead {
            text
          }
          tagged {
            tag {
              uid
              type
              document {
                ... on PrismicTag {
                  id
                  data {
                    name
                  }
                }
              }
            }
          }
          author {
            uid
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
          featured_images {
            image {
              fluid(maxWidth: 600, maxHeight: 320) {
                ...GatsbyPrismicImageFluid
              }
              url
              alt
              thumbnails {
                thumbnail {
                  fluid(maxWidth: 600, maxHeight: 320) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
