import React, { useState, useEffect, useRef } from "react"
import Offer from "@components/Offer/List/Card"
import Post from "@components/Post/PostList/Card"
import { searchTags } from "@services/prismic"
import useInfiniteScroll from "@hooks/use-infinite-scroll"
import Box from "@lib/ui/core/Box"
// import ShrinkedList from "@components/Offer/ShrinkedList"
import Spinner from "@components/Spinner"
import { useStoreActions } from 'easy-peasy'

const richTextFields = [
  {
    path: ['short_description'],
    asText: true,
    asHtml: false,
    asRaw: false,
  },
]

// const getListComponent = (display) => {
//   if (display === "shrink") return ShrinkedList
//   return OfferList
// }

const TagResult = ({
  exclude = [],
  lastPublicationDate = null,
  totalCount = 100,
  // setTotal,
  tagId,
  pageSize = 12,
  // display = "grid",
}) => {
  const total = useRef(totalCount - exclude.length)
  const [items, setItems] = useState([])
  // const skeletonCount = 3

  const updateIsLoading = useStoreActions(({ offerSearch }) => offerSearch.updateIsLoading)
  const getCurrentPage = () => Math.floor(items.length / pageSize) + 1

  const fetchData = async () => {
    const response = await searchTags({
      pageSize,
      page: getCurrentPage() + 1,
      last_publication_date: lastPublicationDate,
      tagId,
    },
    {
      parseRichText: richTextFields,
    })
    const { results } = response
    // setTotal(newTotal)
    // total.current = newTotal
    setItems((prev) => [...prev, ...results])
    updateIsLoading(false)
    return response
  }

  const hasNextPage = () => items.length + pageSize < total.current

  const [anchorRef, { loading, setTrigger }] = useInfiniteScroll({
    inViewOptions: {
      rootMargin: "600px 0px",
    },
    hasNextPage,
    next: fetchData,
  })

  useEffect(() => {
    setTrigger(true)
  }, [])

  // const ListComponent = getListComponent(display)

  return (
    <>
      {(items.length > 0 || hasNextPage()) && (
        items.map((item) => (item.type === "offer" ? <Box key={item.prismicId} mb={2.5}><Offer fullWidth offer={item} /></Box> : <Box mb={2.5} key={item.prismicId}><Post fullWidth post={item} /></Box>))
        // <ListComponent
        //   offers={
        //     loading
        //       ? [...items, ...Array.from(new Array(skeletonCount))]
        //       : items
        //   }
        //   loading={loading}
        // />
      )}
      <Box ref={anchorRef} />
      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <Spinner />
        </Box>
      )}
    </>
  )
}

export default TagResult
